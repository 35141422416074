<template>
  <div>
    <!-- Participants -->
    <b-row align-h="between" class="mb-1">
      <b-col><h2>{{participantType}}</h2></b-col>
      <b-col cols="4">
        <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search (name, job role, organization)" v-model="searchParticipant" class="rounded-lg" debounce="500" />
            </b-input-group>
          </b-form-group>
      </b-col>
    </b-row>
    <b-row class="match-height" v-if="this.$route.meta.type === 'speaker'">
      <b-col cols="12" v-for="participantCategory in Object.keys(participants)" :key="participantCategory">
        <h3>{{participantCategory}}</h3>
        <b-row class="match-height">
          <b-col cols="6" md="4" lg="3" xl="2" v-for="participant in participants[participantCategory]" :key="participant.id">
            <b-link class="text-dark h-100 w-100 d-flex flex-fill align-items-stretch" @click="showParticipantDetail(participant)" :key="participant.id">
              <participant-card :data="participant" :chat="participantType !== 'Speakers'" :call="false" />
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height" v-if="this.$route.meta.type !== 'speaker'">
      <b-col cols="6" md="4" lg="3" xl="2" v-for="participant in participantList" :key="participant.id">
        <b-link class="text-dark h-100 w-100 d-flex flex-fill align-items-stretch" @click="showParticipantDetail(participant)" :key="participant.id">
          <participant-card :data="participant" :chat="participantType !== 'Speakers'" :call="false" />
        </b-link>
      </b-col>
    </b-row>
    <infinite-loading :identifier="infiniteListId" @infinite="loadParticipants" v-if="this.$route.meta.type !== 'speaker'">
        <div slot="no-more"></div>
        <div slot="no-results">No attendees</div>
    </infinite-loading>
    <b-sidebar id='participant-details' v-model="showParticipantSidebar" width="30rem" right shadow backdrop no-header sidebar-class="border-left border-primary">
      <b-button variant="light-primary" style="position:absolute;top:2px;" v-b-toggle.participant-details><font-awesome-icon :icon="['fal', 'times-circle']" size="2x" class="text-primary" /></b-button>
      <ParticipantDetail :data="participantData" />
    </b-sidebar>
    <!-- Participants End-->
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCardGroup,
  BCard,
  BCardText,
  BLink,
  BImg,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BMedia,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import ParticipantCard from "./ParticipantCard.vue";
import ParticipantDetail from './ParticipantDetail.vue';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCardGroup,
    BCard,
    BCardText,
    BLink,
    BImg,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BMedia,
    BTabs,
    BTab,
    ParticipantCard,
    ParticipantDetail,
    InfiniteLoading
  },
  computed: {
    participants() {
      if (this.$route.meta.type === 'speaker') {
       return this.$store.getters['attendees/byTypeCategorized'](this.$route.meta.type, this.searchParticipant)
    } else {
      return this.$store.getters['attendees/byType'](this.$route.meta.type, this.searchParticipant)
    }
    }
  },
  watch: {
    // async participants() {
    //   return this.$store.getters['attendees/byType'](this.$route.meta.type, searchParticipant)
    //   // return this.$store.getters['attendees/attendees']
    // },
    // searchParticipant() {
    //   this.participants = this.$store.getters['attendees/byType'](this.$route.meta.type, this.searchParticipant)
    // }
    participants() {
      // console.log('watch:participants')
      this.page = 0
      this.participantList = []
      this.infiniteListId += 1
    },
    participantType(o,n) {
      // console.log(o,n);
      this.$meta().title = n;
    }
  },
  data() {
    return {
      page: 0,
      participantList: [],
      infiniteListId: +new Date(),
      searchParticipant: '',
      participantType: this.$route.meta.type === 'speaker' ? 'Speakers' : 'Attendees',
      participantData: {},
      showParticipantSidebar: false,
    }
  },
  methods: {
    showParticipantDetail(participant) {
      this.participantData = participant
      this.showParticipantSidebar = true
    },
    loadParticipants($state) {
      const start = this.page * 100
      const end = start + 100
      if (start < this.participants.length) {
        const _list = this.participants.slice(start, end)
        this.participantList.push(..._list)
        this.page += 1
        // console.log('this.participantList', this.participantList)
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  },
  mounted() {
    // console.log('attendees', this.$store.state.attendees);
    // if (!this.$store.state.attendees.attendees || this.$store.state.attendees.attendees.length === 0) {
    // this.$store.dispatch('attendees/getAttendees')
  },
  metaInfo() {
    return {
      title: this.participantType,
    }
  },
  setup(props) {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>

<style>
  
</style>
