<template>
  <b-card
    v-if="data"
    no-body
    class="border-primary shadow flex-grow-1"
  >
    <b-card-img-lazy v-if="data.avatar" :src="data.avatar" :alt="data.name || data.firstname + ' ' + data.middlename + ' ' + data.lastname" top class="rounded-top" />
    <b-card-body class="pb-1">
      <div class="d-block text-center mb-2" v-if="!data.avatar">
        <avatar variant="bauhaus" :size="100" :colors="['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']" :name="data.name || data.firstname + ' ' + data.middlename + ' ' + data.lastname" />
      </div>
      <b-card-title class="mb-0 text-primary">
        <font-awesome-icon v-if="infoIcon" :icon="['fad', 'info-circle']" class="text-primary float-right" size="lg" />
        <span v-if="data.name" class="font-weight-bolder">{{ data.name }}</span><span class="font-weight-bolder" v-else>{{ data.firstname }} {{ data.middlename }} {{ data.lastname }}</span>
      </b-card-title>
      <b-card-text class="mt-0 pt-0">
        <span v-if="data.description">{{ data.description }}<br/></span>
        <span v-else>{{ data.designation }}, {{ data.organization }}</span>
      </b-card-text>
      <div class="mt-1" v-if="data.social">
        <b-link v-for="socialLink in socialLinks" :key="socialLink" :href="data.social[socialLink]" target="_blank">
          <font-awesome-icon v-if="socialLink !== 'website'" :icon="['fab', socialLink]" size="lg" class="mr-1" />
          <font-awesome-icon v-if="socialLink === 'website'" :icon="['fal', 'globe']" size="lg" class="mr-1" />
        </b-link>
      </div>
      <!-- <b-button variant="primary" class="mt-1 w-100" v-b-toggle.participant-details>View Profile</b-button> -->
      <!-- <b-button variant="primary" class="mt-1 w-100" @click="showParticipantDetails">View Profile</b-button> -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BImgLazy, BLink, BButton, BSidebar, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import Avatar from 'vue2-boring-avatars'
import ParticipantDetail from './ParticipantDetail.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BImgLazy,
    BLink,
    BButton,
    BSidebar,
    Avatar,
    ParticipantDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  computed: {
    socialLinks() {
      return Object.keys(this.data.social).filter(link => this.data.social[link] !== '')
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    chat: {
      type: Boolean,
      default: () => false,
    },
    call: {
      type: Boolean,
      default: () => false,
    },
    infoIcon: {
      type: Boolean,
      default: () => true,
    },
  },
}
</script>
